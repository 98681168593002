import React, {useEffect, useRef} from 'react';
import '../styles/error_page_styles.css'
import {Canvas} from "@react-three/fiber";
import {OrbitControls, QuadraticBezierLine} from "@react-three/drei";
import FrontEndGroup, {BackEndGroup, GeneralGroup} from "./custom_three_elements/SkillTreeGroup";
import SkillTreeElement from "./custom_three_elements/SkillTreeElement";
import {disableAllHighlights, setHighlights} from "./customMaps/SkillTreeMap";
import {useLoaderData} from "react-router-dom";
import {notify} from "../App";
import * as THREE from "three";


//MAIN SKILLS PAGE
const Skills = () => {
    const data = useLoaderData();
    const hasNotified = useRef(false); // Add a ref to track notification

    useEffect(() => {
        if (data.message !== 'no data') {
            setHighlights(data.skill_ids);
            if(!hasNotified.current){
                notify("Skills used in project are now highlighted with ⬜.", "info");
                hasNotified.current = true;
            }
        } else {
            disableAllHighlights();
        }
    }, [data]);


    return (
        <>
            <div className="canvasContainer">
                <Canvas camera={{position:[2,-7,12]}}>
                    <OrbitControls
                        enableZoom={true}
                        enableRotate={false}
                        target={[2,-7,0]}
                        mouseButtons={{
                            LEFT: THREE.MOUSE.PAN,
                            MIDDLE: THREE.MOUSE.DOLLY,
                            RIGHT: null
                        }}
                    />
                    <ambientLight intensity={Math.PI / 2}/>
                    <SkillTreeElement textContent={'Skills'} isTitle={true} position={[0,0,0]}/>
                    <QuadraticBezierLine start={[0, 0, 0]} end={[-20, -5, 0]} mid={[-10, 0, 0]} color="#304C3D" lineWidth={5}/>{/*FRONT END*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[0, -5, 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*BACK END*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[20, -5, 0]} mid={[10, 0, 0]} color="#304C3D" lineWidth={5}/>{/*GENERAL*/}
                    <FrontEndGroup groupPosition={[-20,-5,0]}/>
                    <BackEndGroup groupPosition={[0,-5,0]}/>
                    <GeneralGroup groupPosition={[20,-5,0]}/>
                </Canvas>
            </div>
        </>
    );
};

export default Skills;

export const skillsLoader = async ({ request }) => {
    const url = new URL(request.url);
    const id = url.searchParams.get('id');

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Application-Token': '1pNNMwvQ23wFisTRojRGdKl0nDWx05vGrnBQQaRigOk4kfbBQ3qfjut8ZGHv'
    });

    let res;
    if (id) {
        res = await fetch(`https://api.zacharyvds.be/public/api/projects/${id}/skills`, { headers });
    } else {
        return { message: 'no data' };
    }

    if (!res.ok) {
        throw new Error(`Failed to fetch data${id ? ` for ID: ${id}` : ''}`);
    }

    return await res.json();
};