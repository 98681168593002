import React from "react";
import Home from './Components/Home.js'
import './app.css'
import {createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider, Link} from 'react-router-dom'
import {dataLoader} from './Components/Home.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import NotFound from "./Components/NotFound";
import Skills, {skillsLoader} from "./Components/Skills";
import {AnimatePresence} from "framer-motion";
//TOASTIFY REQUIRES BOTH LOGIC AND THEIR OWN CSS STYLING FOR IT TO WORK AS INTENDED.
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'



function App() {
    const router = setupRouter();
  return (
    <div className="App">
        <AnimatePresence mode={"wait"}>
            <ToastContainer key={"ToastContainer"} newestOnTop />
            <RouterProvider key={"Router"} router={router}/>
        </AnimatePresence>
    </div>
  );
}

export default App;

function setupRouter(){
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path='/' element={<Root />}>
                <Route index element={<Home />} loader={dataLoader}/>
                <Route path='/skills' element={<Skills/>} loader={skillsLoader}/>
                <Route path="*" element={<NotFound />} />
            </Route>
        )
    )
    return router;
}
const Root = () => {
    const currentYear = new Date().getFullYear();
    return <>
        <nav>
            <Link to={'/'}>Home</Link>
            <Link to={'/skills'}>Skills</Link>
        </nav>
        <main>
            <Outlet/>
        </main>
        <footer>
            <p>© {currentYear} Zachary Van De Staey</p>
        </footer>
    </>
}

export function notify(message, type){
    toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "info"
    });
}