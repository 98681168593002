
import {Canvas, useThree} from '@react-three/fiber'
import { useLoaderData,useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {Html} from "@react-three/drei";
import "../styles/3element_styling.css"
import zImg from "../Assets/zachary_image.webp"
import instagramLogo from "../Assets/social_logos/instagramLogo.png"
import linkedInLogo from "../Assets/social_logos/linkedInLogo.png"
import {useEffect} from "react";

gsap.registerPlugin(useGSAP,ScrollTrigger)
const spacingMultiplier = 5;
const indexOffset = 1;


//CAMERA CONTROLLING
function CameraAnimation({ amountOfProjects }) {
    const { camera } = useThree();
    const main = document.querySelector('.HomeContainer');
    camera.position.set(0,0,5);

    useGSAP(() => {
        const customTmeLine = gsap.timeline({
            scrollTrigger: {
                trigger: main,
                start:'top top',
                end:'bottom top,',
                scrub:2.5,

            },
        })
        customTmeLine
            .to(camera.position, { duration: 2, z: (amountOfProjects + 2 ) * spacingMultiplier }, 0) // Move right
            .to(camera.position, { duration: 2, x: (amountOfProjects + 1) * spacingMultiplier}, 0)

    }, {scope:main})
}

//CAAMERA SETTINGS
function CameraSettings() {
    const { camera } = useThree();

    useEffect(() => {
        // Set camera properties
        camera.fov = 60; // Adjust field of view as needed
        camera.near = 0.1; // Adjust near clipping plane
        camera.far = 5; // Adjust far clipping plane
        camera.updateProjectionMatrix(); // Update projection matrix to apply changes
    }, [camera]);

    return null;
}
//MAIN HOME COMPONENT
const Home = () => {
    let data = useLoaderData();
    data = data.data;
    data.sort((a,b) => new Date(b.date) - new Date(a.date));
    const amountOfProjects = data.length;
    const containerHeight = (amountOfProjects ) * 85;

    const navigate = useNavigate(); // Use navigate hook
    const handleSkillsButtonClick = (projectId) => {
        navigate(`/skills?id=${projectId}`); // Navigate with query parameter
    };
    return (
        <div className="HomeContainer" style={{height: `${containerHeight}svh`}}>
            <div className="canvasContainer">
                <Canvas>
                    <CameraAnimation amountOfProjects={amountOfProjects}/>
                    <CameraSettings/>
                    <ambientLight intensity={Math.PI / 2} />
                    <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
                    <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
                    <Html transform position={[0,0,0]}>
                        <article className="name-container">
                            <div className="textContainer">
                                <h2>Zachary Van De Staey </h2>
                                <p id="school">Student Toegepaste Informatica</p>
                                <a href="https://www.kdg.be/" rel={"noreferrer"} target="_blank" id="schoolUrl">Karel de Grote Hogeschool</a>
                            </div>
                            <div className="imgContainer">
                                <img src={zImg} alt={"portret Zachary Van De Staey"} />
                            </div>
                        </article>
                    </Html>
                    {data.map((item, index) => (
                        <Html key={'projectelement_'+index} transform position={[((index + indexOffset) * spacingMultiplier), 0, ((index + indexOffset) * spacingMultiplier)]}>
                            <article className="content-container">
                               <div className="explanationContainer">
                                   <h2>{item.name}</h2>
                                   <p className="date">{item.date}</p>
                                   <p className="description">{item.description}</p>
                                   <a className={"skillTreeButton"} onClick={() => handleSkillsButtonClick(item.id)}>Bekijk gebruikte skills</a>
                               </div>
                                <div className="imageContainer">
                                    <img src={item.imgUrl} alt={item.name + "img"}/>
                                </div>
                            </article>
                        </Html>
                    ))}
                    <Html transform position={[((amountOfProjects + indexOffset) * spacingMultiplier), 0, ((amountOfProjects + indexOffset) * spacingMultiplier)]}>
                        <article className="contact-container">
                            <div className="textContainer">
                                <h2>Contact me!</h2>
                                <p>contact@zacharyvds.be</p>
                                <div className="socialContainer">
                                    <a target="_blank"  rel={"noreferrer"} href="https://www.instagram.com/_zacharyplayss_/">
                                        <img src={instagramLogo} alt="instagram logo"/>
                                    </a>
                                    <a target="_blank" rel={"noreferrer"} href="https://www.linkedin.com/in/zachary-van-de-staey-04ba56229/">
                                        <img src={linkedInLogo} alt="linkedin logo"/>
                                    </a>
                                </div>
                            </div>
                        </article>
                    </Html>
                </Canvas>

            </div>
        </div>
    );
}

export default Home;
//DATA LOADING FUNCTION
export const dataLoader = async () => {
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Application-Token': '1pNNMwvQ23wFisTRojRGdKl0nDWx05vGrnBQQaRigOk4kfbBQ3qfjut8ZGHv'
    });
    const res = await fetch('https://api.zacharyvds.be/public/api/projects',{headers});
    return await res.json()
}