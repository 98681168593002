import React, {useRef, useState} from "react";
import TextThree from "./TextThree";
import FakeGlowMaterial from './FakeGlowMaterial'

function SkillTreeElement({ position, textContent, isTitle, isHighlighted }) {
    const meshRef = useRef();
    const [hovered, setHover] = useState(false);
    const [active, setActive] = useState(false);

    const rotation = active && !isTitle ? 0.35 : 0;

    const handleClick = (event) => {
        /*if (!isTitle) {
            setActive(!active);
        }*/
    };

    return (
        <>
            {/* GLOW MESH - Render only if not a title */}
            {!isTitle && isHighlighted && (
                <mesh
                    position={[position[0], position[1], position[2] - 0.5]}  // Adjust the z-position to move the glow backward
                    scale={2}
                >
                    <planeGeometry args={[1.1, 1.1]} />
                    <FakeGlowMaterial
                        depthTest={true}
                        glowColor={'#fff'}
                        falloff={0.1}
                        glowInternalRadius={6}
                        glowSharpness={5}
                    />
                </mesh>
            )}

            {/* MAIN MESH */}
            <mesh
                position={position}
                ref={meshRef}
                scale={1}
                rotation={[0, rotation, 0]}
                onClick={handleClick}
                onPointerOver={() => !isTitle && setHover(true)}
                onPointerOut={() => !isTitle && setHover(false)}
            >
                <meshBasicMaterial color={(hovered ?  '#fff' : '#304C3D')}/>
                <boxGeometry args={[1.8, 1.8, 1]}/>
                <TextThree
                    textColor={isTitle ? '#fff' : (hovered ? '#304C3D' : '#fff')}
                    textPosition={[-0.8, 0.125, 0.25]}
                    textSize={0.2}
                    textHeight={0.25}
                    textContent={textContent}

                />
            </mesh>
        </>
    );
}

export default SkillTreeElement;