import { extend } from '@react-three/fiber'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import myFont from '../../Assets/fonts/Roboto_Regular.json'

extend({ TextGeometry })

export default function TextThree({textPosition, textSize,textHeight,textContent,textColor}) {
    const font = new FontLoader().parse(myFont);

    return(
        <mesh position={textPosition}>
            <textGeometry args={[textContent, {font, size: textSize, depth: textHeight}]}/>
            <meshLambertMaterial attach='material' color={textColor}/>
        </mesh>
    );
}