export const SkillTreeMap = {
    1: "feg1",   // FrontEndGroup
    2: "feg2", //WEB
    3: "feg3",//Browser Extensions
    4: "feg4", // Mobile App
    5: "feg5", // Desktop App
    6: "feg6", // HTML
    7: "feg7", //CSS
    8: "feg9", //JS
    9: "feg10", //ANDROID
    10: "feg11", // JAVAFX
    11: "feg12", //.NET WPF
    12: "feg13",//BOOTSTRAP
    13: "feg14", //NPMS
    14: "feg15", //WEBPACK
    15: "feg16", //REACT
    16: "beg1",  // BackEndGroup
    17: "beg2", //JAVA BASICS
    18: "beg3", // C
    19: "beg4", // ARDUINO
    20: "beg5", //PHP
    21: "beg6", //DATA
    22: "beg7", //JAVA EXTENDED
    23: "beg9", //C#
    24: "beg10", //.NET
    25: "beg11", //LARAVEL
    26: "beg12", //SQL
    27: "beg13", //XML
    28: "beg14", //JSON
    29: "beg15",//.NET EXTENDED
    30: "beg16",//MYSQL
    31: "beg17",//POSTGRESQL
    32: "beg18",//ORACLE (CLOUD)
    33:"beg19", // PLSQL
    34:"beg20", //BULK OPERATIONS
    35: "gg1",   // GeneralGroup
    36: "gg2", //TEAM
    37: "gg3", //SYSTEMS
    38: "gg4", //VERSION CONTROL
    39: "gg5", ///NETWORK BASICS
    40: "gg6", //OPERATING SYSTEMS
    41: "gg7", //GIT
    42: "gg8", //SUBNETTING
    43: "gg9", // CISCO DEVICE CONFIG
    44: "gg10", // WINDOWS
    45: "gg11", //LINUX
    46: "gg12" //CI-CD
};

export let highlightMap = {
    "feg1": false,
    "feg2": false,
    "feg3": false,
    "feg4": false,
    "feg5": false,
    "feg6": false,
    "feg7": false,
    "feg9": false,
    "feg10": false,
    "feg11": false,
    "feg12": false,
    "feg13": false,
    "feg14": false,
    "feg15": false,
    "feg16": false,
    "beg1": false,
    "beg2": false,
    "beg3": false,
    "beg4": false,
    "beg5": false,
    "beg6": false,
    "beg7": false,
    "beg9": false,
    "beg10": false,
    "beg11": false,
    "beg12": false,
    "beg13": false,
    "beg14": false,
    "beg15": false,
    "beg16": false,
    "beg17": false,
    "beg18": false,
    "gg1": false,
    "gg2": false,
    "gg3": false,
    "gg4": false,
    "gg5": false,
    "gg6": false,
    "gg7": false,
    "gg8": false,
    "gg9": false,
    "gg10": false,
    "gg11": false,
    "gg12": false,
};

export function setHighlights(indexArray){
    const namesToHighlight = new Set(indexArray.map(index => SkillTreeMap[index]));
    for (let key in highlightMap) {
        if (namesToHighlight.has(key)) {
            highlightMap[key] = true;
        } else {
            highlightMap[key] = false;
        }
    }
}

export function disableAllHighlights(){
    for (let key in highlightMap) {
        highlightMap[key] = false;
    }
}
