import { extend } from '@react-three/fiber'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import {QuadraticBezierLine} from "@react-three/drei";
import React from "react";
import SkillTreeElement from "./SkillTreeElement";
import {highlightMap} from "../customMaps/SkillTreeMap";

const positionIncrement = 2.5;
extend({ TextGeometry })

export default function FrontEndGroup({ groupPosition, textPosition, textSize, textHeight, textContent, textColor }) {
    return(
        <>
            <group position={groupPosition}>
                {/* Skill Tree level 2 */}
                <SkillTreeElement key={"feg1"} position={[0, 0, 0]}  textContent={'Front \nEnd'} isTitle={true} isHighlighted={highlightMap["feg1"]} />
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[0, -(positionIncrement), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*WEB*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[(positionIncrement), -(positionIncrement), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*EXTENSIONS*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[(positionIncrement * 4), -(positionIncrement), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*MOBILE APP*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[(positionIncrement * 5), -(positionIncrement), 0]} mid={[(positionIncrement * 5), 1, 0]} color="#304C3D" lineWidth={5}/>{/*DESKTOP APP*/}
                {/* Skill Tree level 3 */}
                <SkillTreeElement key={"feg2"} position={[0, -(positionIncrement), 0]} textContent={'Web'} isHighlighted={highlightMap["feg2"]} />
                <SkillTreeElement key={"feg3"} position={[(positionIncrement), -(positionIncrement), 0]} textContent={'Browser\nExtensions'} isHighlighted={highlightMap["feg3"]}/>
                <SkillTreeElement key={"feg4"} position={[(positionIncrement * 4), -(positionIncrement), 0]} textContent={'Mobile\nApp'} isHighlighted={highlightMap["feg4"]}/>
                <SkillTreeElement key={"feg5"} position={[(positionIncrement * 5), -(positionIncrement), 0]} textContent={'Desktop\nApp'} isHighlighted={highlightMap["feg5"]}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[0, -(positionIncrement), 0]} end={[0, -(positionIncrement * 2), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*WEB -> HTML*/}
                    <QuadraticBezierLine start={[0, -(positionIncrement), 0]} end={[(positionIncrement), -(positionIncrement * 2), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*WEB -> CSS*/}
                    <QuadraticBezierLine start={[0, -(positionIncrement), 0]} end={[(positionIncrement * 2), -(positionIncrement * 2), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*WEB -> JS*/}
                    <QuadraticBezierLine start={[(positionIncrement * 4), -(positionIncrement), 0]} end={[(positionIncrement * 4), -(positionIncrement * 2), 0]} mid={[(positionIncrement *4), 0, 0]} color="#304C3D" lineWidth={5}/>{/*MobileApp -> JAVAFX*/}
                    <QuadraticBezierLine start={[(positionIncrement * 5), -(positionIncrement), 0]} end={[(positionIncrement * 5), -(positionIncrement * 2), 0]} mid={[(positionIncrement *5),0, 0]}  color="#304C3D" lineWidth={5}/>{/*MobileApp -> JAVAFX*/}
                    <QuadraticBezierLine start={[(positionIncrement * 5), -(positionIncrement), 0]} end={[(positionIncrement * 6), -(positionIncrement * 2), 0]} mid={[(positionIncrement *6),-1.5, 0]}  color="#304C3D" lineWidth={5}/>{/*MobileApp -> .NET WPF*/}
                {/* Skill Tree level 4 */}
                <SkillTreeElement key={"feg6"} position={[0, -(positionIncrement * 2), 0]} textContent={'Html'} isHighlighted={highlightMap["feg6"]}/>
                <SkillTreeElement key={"feg7"} position={[(positionIncrement), -(positionIncrement * 2), 0]} textContent={'CSS'} isHighlighted={highlightMap["feg7"]}/>
                <SkillTreeElement key={"feg9"} position={[(positionIncrement * 2), -(positionIncrement * 2), 0]} textContent={'JS'} isHighlighted={highlightMap["feg9"]}/>
                <SkillTreeElement key={"feg10"} position={[(positionIncrement * 4), -(positionIncrement * 2), 0]} textContent={'Android'} isHighlighted={highlightMap["feg10"]}/>
                <SkillTreeElement key={"feg11"} position={[(positionIncrement * 5), -(positionIncrement * 2), 0]} textContent={'JavaFX'} isHighlighted={highlightMap["feg11"]}/>
                <SkillTreeElement key={"feg12"} position={[(positionIncrement * 6), -(positionIncrement * 2), 0]} textContent={'.NET\nWPF'} isHighlighted={highlightMap["feg12"]}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[(positionIncrement), -(positionIncrement * 2), 0]} end={[(positionIncrement), -(positionIncrement * 3), 0]} mid={[(positionIncrement), 0, 0]} color="#304C3D" lineWidth={5}/>{/* CSS -> Bootstrap*/}
                    <QuadraticBezierLine start={[(positionIncrement * 2), -(positionIncrement * 3), 0]} end={[(positionIncrement * 2), -(positionIncrement * 3), 0]} mid={[(positionIncrement * 2), -(positionIncrement), 0]} color="#304C3D" lineWidth={5}/>{/* JS -> NPM*/}
                {/* Skill Tree level 5 */}
                <SkillTreeElement key={"feg13"} position={[(positionIncrement), -(positionIncrement * 3), 0]} textContent={'Bootstrap'} isHighlighted={highlightMap["feg13"]}/>
                <SkillTreeElement key={"feg14"} position={[(positionIncrement * 2), -(positionIncrement * 3), 0]} textContent={'NPM'} isHighlighted={highlightMap["feg14"]}/>
                <SkillTreeElement key={"feg15"} position={[(positionIncrement * 3), -(positionIncrement * 3), 0]} textContent={'Webpack'} isHighlighted={highlightMap["feg15"]}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[(positionIncrement * 2), -(positionIncrement * 3), 0]} end={[(positionIncrement * 2), -(positionIncrement * 4), 0]} mid={[(positionIncrement * 2), -(positionIncrement), 0]} color="#304C3D" lineWidth={5}/>{/* NPM -> React*/}
                    <QuadraticBezierLine start={[(positionIncrement * 2), -(positionIncrement * 3), 0]} end={[(positionIncrement * 3), -(positionIncrement * 3), 0]} mid={[(positionIncrement * 3), -(positionIncrement * 3), 0]} color="#304C3D" lineWidth={5}/>{/* WEBPACK -> NPM*/}
                {/* Skill Tree level 6 */}
                <SkillTreeElement key={"feg16"} position={[(positionIncrement * 2), -(positionIncrement * 4), 0]} textContent={'React'} isHighlighted={highlightMap["feg16"]} />
            </group>
        </>
    );
}

export function BackEndGroup({ groupPosition, textPosition, textSize, textHeight, textContent, textColor }) {
    return(
        <>
            <group position={groupPosition}>
                {/* Skill Tree level 2 */}
                <SkillTreeElement key={"beg1"} position={[0, 0, 0]}  textContent={'Back \nEnd'} isTitle={true} isHighlighted={highlightMap["beg1"]}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[0, -(positionIncrement), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*BACK END -> JAVA BASICS*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[(positionIncrement), -(positionIncrement), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*BACK END -> C*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[(positionIncrement * 3), -(positionIncrement), 0]} mid={[(positionIncrement * 2), 0, 0]} color="#304C3D" lineWidth={5}/>{/*BACK END -> PHP*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[(positionIncrement * 4), -(positionIncrement), 0]} mid={[(positionIncrement * 4), 0, 0]} color="#304C3D" lineWidth={5} />{/*BACK END -> DATA*/}
                {/* Skill Tree level 3 */}
                <SkillTreeElement key={"beg2"} position={[0, -(positionIncrement), 0]} textContent={'Java\nBasics'} isHighlighted={highlightMap["beg2"]} />
                <SkillTreeElement key={"beg3"} position={[(positionIncrement), -(positionIncrement), 0]} textContent={'C'} isHighlighted={highlightMap["beg3"]}/>
                <SkillTreeElement key={"beg4"} position={[(positionIncrement * 2), -(positionIncrement), 0]} textContent={'Arduino'} isHighlighted={highlightMap["beg4"]}/>
                <SkillTreeElement key={"beg5"} position={[(positionIncrement * 3), -(positionIncrement), 0]} textContent={'PHP'} isHighlighted={highlightMap["beg5"]} />
                <SkillTreeElement key={"beg6"} position={[(positionIncrement * 4), -(positionIncrement), 0]} textContent={'Data'} isHighlighted={highlightMap["beg6"]} isTitle={true}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[0, -(positionIncrement), 0]} end={[0, -(positionIncrement * 2), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*JAVA BASICS -> java extended*/}
                    <QuadraticBezierLine start={[(positionIncrement), -(positionIncrement), 0]} end={[(positionIncrement), -(positionIncrement * 2), 0]} mid={[(positionIncrement), 0, 0]} color="#304C3D" lineWidth={5}/>{/*C -> C#*/}
                    <QuadraticBezierLine start={[(positionIncrement), -(positionIncrement * 2), 0]} end={[(positionIncrement * 2), -(positionIncrement * 2), 0]} mid={[(positionIncrement),  -(positionIncrement * 2), 0]} color="#304C3D" lineWidth={5}/>{/*C# -> .NET*/}
                    <QuadraticBezierLine start={[(positionIncrement), -(positionIncrement), 0]} end={[(positionIncrement * 2), -(positionIncrement), 0]} mid={[(positionIncrement),-(positionIncrement), 0]} color="#304C3D" lineWidth={5}/>{/*C -> ARDUINO*/}
                    <QuadraticBezierLine start={[(positionIncrement * 3), -(positionIncrement), 0]} end={[(positionIncrement * 3), -(positionIncrement * 2), 0]} mid={[(positionIncrement * 3), -(positionIncrement * 2), 0]} color="#304C3D" lineWidth={5}/>{/*PHP -> LARAVEL*/}
                    <QuadraticBezierLine start={[(positionIncrement * 4), -(positionIncrement), 0]} end={[(positionIncrement * 4), -(positionIncrement * 2), 0]} mid={[(positionIncrement * 4), -(positionIncrement * 2), 0]} color="#304C3D" lineWidth={5}/>{/*DATA -> SQL*/}
                    <QuadraticBezierLine start={[(positionIncrement * 4), -(positionIncrement), 0]} end={[(positionIncrement * 5), -(positionIncrement * 2), 0]} mid={[(positionIncrement * 5), -(positionIncrement * 2), 0]} color="#304C3D" lineWidth={5}/>{/*DATA -> XML*/}
                    <QuadraticBezierLine start={[(positionIncrement * 4), -(positionIncrement), 0]} end={[(positionIncrement * 6), -(positionIncrement * 2), 0]} mid={[(positionIncrement * 6), -(positionIncrement * 1.5), 0]} color="#304C3D" lineWidth={5}/>{/*DATA -> JSON*/}
                {/* Skill Tree level 4 */}
                <SkillTreeElement key={"beg7"} position={[0, -(positionIncrement * 2), 0]} textContent={'Java\nExtended'} isHighlighted={highlightMap["beg7"]}/>
                <SkillTreeElement key={"beg9"} position={[(positionIncrement), -(positionIncrement * 2), 0]} textContent={'C#'} isHighlighted={highlightMap["beg9"]}/>
                <SkillTreeElement key={"beg10"} position={[(positionIncrement * 2), -(positionIncrement * 2), 0]} textContent={'.NET'} isHighlighted={highlightMap["beg10"]}/>
                <SkillTreeElement key={"beg11"} position={[(positionIncrement * 3), -(positionIncrement * 2), 0]} textContent={'Laravel'} isHighlighted={highlightMap["beg11"]}/>
                <SkillTreeElement key={"beg12"} position={[(positionIncrement * 4), -(positionIncrement * 2), 0]} textContent={'SQL'} isHighlighted={highlightMap["beg12"]}/>
                <SkillTreeElement key={"beg13"} position={[(positionIncrement * 5), -(positionIncrement * 2), 0]} textContent={'XML'} isHighlighted={highlightMap["beg13"]}/>
                <SkillTreeElement key={"beg14"} position={[(positionIncrement * 6), -(positionIncrement * 2), 0]} textContent={'JSON'} isHighlighted={highlightMap["beg14"]}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[(positionIncrement * 2), -(positionIncrement * 2), 0]} end={[(positionIncrement * 2), -(positionIncrement * 3), 0]} mid={[(positionIncrement * 2), -(positionIncrement * 1.5), 0]} color="#304C3D" lineWidth={5}/>{/*DATA -> JSON*/}
                    <QuadraticBezierLine start={[(positionIncrement * 4), -(positionIncrement * 2), 0]} end={[(positionIncrement * 3), -(positionIncrement * 3), 0]} mid={[(positionIncrement * 4), -(positionIncrement * 1.5), 0]} color="#304C3D" lineWidth={5}/>{/*DATA -> JSON*/}
                    <QuadraticBezierLine start={[(positionIncrement * 4), -(positionIncrement * 2), 0]} end={[(positionIncrement * 4), -(positionIncrement * 3), 0]} mid={[(positionIncrement * 4), -(positionIncrement * 1.5), 0]} color="#304C3D" lineWidth={5}/>{/*DATA -> JSON*/}
                    <QuadraticBezierLine start={[(positionIncrement * 4), -(positionIncrement * 2), 0]} end={[(positionIncrement * 5), -(positionIncrement * 3), 0]} mid={[(positionIncrement * 4), -(positionIncrement * 1.5), 0]} color="#304C3D" lineWidth={5}/>{/*DATA -> JSON*/}
                {/* Skill Tree level 5 */}
                <SkillTreeElement key={"beg15"} position={[(positionIncrement * 2), -(positionIncrement * 3), 0]} textContent={'.NET\nExtended'} isHighlighted={highlightMap["beg15"]}/>
                <SkillTreeElement key={"beg16"} position={[(positionIncrement * 3), -(positionIncrement * 3), 0]} textContent={'MYSQL'} isHighlighted={highlightMap["beg16"]}/>
                <SkillTreeElement key={"beg17"} position={[(positionIncrement * 4), -(positionIncrement * 3), 0]} textContent={'PostgreSQL'} isHighlighted={highlightMap["beg17"]}/>
                <SkillTreeElement key={"beg18"} position={[(positionIncrement * 5), -(positionIncrement * 3), 0]} textContent={'Oracle\n(cloud)'} isHighlighted={highlightMap["beg18"]}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[(positionIncrement * 5), -(positionIncrement * 3), 0]} end={[(positionIncrement * 5), -(positionIncrement * 4), 0]} mid={[(positionIncrement * 5), -(positionIncrement * 3.5), 0]} color="#304C3D" lineWidth={5}/>{/*DATA -> JSON*/}
                    <QuadraticBezierLine start={[(positionIncrement * 5), -(positionIncrement * 4), 0]} end={[(positionIncrement * 5), -(positionIncrement * 5), 0]} mid={[(positionIncrement * 5), -(positionIncrement * 3.5), 0]} color="#304C3D" lineWidth={5}/>{/*DATA -> JSON*/}

                {/* Skill Tree level 6 */}
                <SkillTreeElement key={"beg19"} position={[(positionIncrement *5), -(positionIncrement * 4), 0]} textContent={'PLSQL'} isHighlighted={highlightMap["beg19"]}/>
                {/* Skill Tree level 7 */}
                <SkillTreeElement key={"beg20"} position={[(positionIncrement *5), -(positionIncrement * 5), 0]} textContent={'Bulk\nOperaties'} isHighlighted={highlightMap["beg20"]}/>
            </group>
        </>
    );
}

export function GeneralGroup({ groupPosition, textPosition, textSize, textHeight, textContent, textColor }) {
    return(
        <>
            <group position={groupPosition}>
                {/* Skill Tree level 2 */}
                <SkillTreeElement key={"gg1"} position={[0, 0, 0]}  textContent={'General'} isTitle={true} isHighlighted={highlightMap["gg1"]}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[0, -(positionIncrement), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*General -> TEAMS*/}
                    <QuadraticBezierLine start={[0, 0, 0]} end={[(positionIncrement), -(positionIncrement), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*General -> SYSTEMS*/}
                {/* Skill Tree level 2 */}
                <SkillTreeElement key={"gg2"} position={[0, -(positionIncrement), 0]}  textContent={'Team'} isHighlighted={highlightMap["gg2"]} isTitle={true}/>
                <SkillTreeElement key={"gg3"} position={[(positionIncrement), -(positionIncrement), 0]}  textContent={'Systems'} isHighlighted={highlightMap["gg3"]} isTitle={true}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[0, -(positionIncrement), 0]} end={[0, -(positionIncrement * 2), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*TEAMS -> VERSIONCONTROL*/}
                    <QuadraticBezierLine start={[(positionIncrement), -(positionIncrement), 0]} end={[(positionIncrement), -(positionIncrement * 2), 0]} mid={[(positionIncrement), 0, 0]} color="#304C3D" lineWidth={5}/>{/*SYSTEMS -> Network Basics*/}
                    <QuadraticBezierLine start={[(positionIncrement), -(positionIncrement), 0]} end={[(positionIncrement * 3), -(positionIncrement * 2), 0]} mid={[(positionIncrement * 2), -(positionIncrement), 0]} color="#304C3D" lineWidth={5}/>{/*SYSTEMS -> OPERATING SYSTEMS*/}
                {/* Skill Tree level 3 */}
                <SkillTreeElement key={"gg4"} position={[0, -(positionIncrement*2), 0]}  textContent={'Version\nControl'} isHighlighted={highlightMap["gg4"]} />
                <SkillTreeElement key={"gg5"} position={[(positionIncrement), -(positionIncrement*2), 0]}  textContent={'Network\nBasics'} isHighlighted={highlightMap["gg5"]}/>
                <SkillTreeElement key={"gg6"} position={[(positionIncrement * 3), -(positionIncrement*2), 0]}  textContent={'Operating\nSystems'} isHighlighted={highlightMap["gg6"]}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[0, -(positionIncrement * 2), 0]} end={[0, -(positionIncrement * 3), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*VERSIONCTONROL ->  GIT*/}
                    <QuadraticBezierLine start={[(positionIncrement), -(positionIncrement * 2), 0]} end={[(positionIncrement), -(positionIncrement * 3), 0]} mid={[(positionIncrement), 0, 0]} color="#304C3D" lineWidth={5}/>{/*SYSTEMS -> Network Basics*/}
                    <QuadraticBezierLine start={[(positionIncrement), -(positionIncrement * 2), 0]} end={[(positionIncrement * 2), -(positionIncrement * 3), 0]} mid={[(positionIncrement * 2), -(positionIncrement * 2), 0]} color="#304C3D" lineWidth={5}/>{/*SYSTEMS -> Network Basics*/}
                    <QuadraticBezierLine start={[(positionIncrement * 3), -(positionIncrement * 2), 0]} end={[(positionIncrement * 3), -(positionIncrement * 3), 0]} mid={[(positionIncrement * 3), -(positionIncrement * 2), 0]} color="#304C3D" lineWidth={5}/>{/*OPERATING SYSTEMS -> WINDOWS*/}
                    <QuadraticBezierLine start={[(positionIncrement * 3), -(positionIncrement * 2), 0]} end={[(positionIncrement * 4), -(positionIncrement * 3), 0]} mid={[(positionIncrement * 4), -(positionIncrement * 2), 0]} color="#304C3D" lineWidth={5}/>{/*OPERATING SYSTEMS -> LINUX*/}
                {/* Skill Tree level 4 */}
                <SkillTreeElement key={"gg7"} position={[0, -(positionIncrement * 3), 0]}  textContent={'GIT'} isHighlighted={highlightMap["gg7"]}/>
                <SkillTreeElement key={"gg8"} position={[(positionIncrement), -(positionIncrement * 3), 0]}  textContent={'Subnetting'} isHighlighted={highlightMap["gg8"]}/>
                <SkillTreeElement key={"gg9"} position={[(positionIncrement * 2), -(positionIncrement * 3), 0]}  textContent={'Cisco\nDevice config'} isHighlighted={highlightMap["gg9"]}/>
                <SkillTreeElement key={"gg10"} position={[(positionIncrement * 3), -(positionIncrement *3), 0]}  textContent={'Windows'} isHighlighted={highlightMap["gg10"]}/>
                <SkillTreeElement key={"gg11"} position={[(positionIncrement * 4), -(positionIncrement *3), 0]}  textContent={'Linux'} isHighlighted={highlightMap["gg11"]}/>
                    {/*CONNECTIONS*/}
                    <QuadraticBezierLine start={[0, -(positionIncrement * 3), 0]} end={[0, -(positionIncrement * 4), 0]} mid={[0, 0, 0]} color="#304C3D" lineWidth={5}/>{/*GIT -> CI/CD*/}
                {/* Skill Tree level 5 */}
                <SkillTreeElement key={"gg12"} position={[0, -(positionIncrement * 4), 0]}  textContent={'CI/CD'} isHighlighted={highlightMap["gg12"]}/>
            </group>
        </>
    );
}
