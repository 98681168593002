import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/error_page_styles.css'

const NotFound = () => {
    return (
        <div className="notfound">
            <h1>404</h1>
            <p>Oops! The page you're looking for does not exist.</p>
            <p>
                <Link to="/public">Go back to the homepage</Link>
            </p>
        </div>
    );
};

export default NotFound;